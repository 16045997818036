import Introduction from "./Introduction/Introduction";
import Tech from "./Tech/Tech";
import About from "./About/About";

import "./Section.css";
import { Jobs, Projects, Clubs } from "./CardsList/CardsList";

function Section() {
  return (
    <div>
      <section className="sec-first flex-centered w-100">
        <div className="sec-content">
          <Introduction />
        </div>
      </section>

      <section className="flex-centered w-100">
        <div className="sec-tech sec-content">
          <div className="title center-text">(click the icons!)</div>
          <Tech />
        </div>
      </section>

      <section className="flex-centered w-100">
        <div className="sec-content ">
          <About />
        </div>
      </section>

      <section className="flex-centered w-100">
        <div className="sec-content ">
          <div className="card-title title">Work Experience</div>
          <Jobs />
        </div>
      </section>

      <section className="flex-centered w-100">
        <div className="sec-content ">
          <div className="card-title title">My Projects</div>
          <Projects />
        </div>
      </section>

      <footer className="flex-centered w-100">
        <div className="footer">
          <div>Developed by Dylan Leclair © 2021-2022</div>
          <div className="pad-top">
            <a href="mailto:dylan.leclair@icloud.com">
              dylan.leclair@icloud.com
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Section;
