import './Navbar.css'

import { motion } from "framer-motion";



const animation = {
    hidden: {
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
            staggerChildren: 0.07,
            delayChildren: 0.2
        },

    },
    exit: {
        opacity: 0,
        transition: {
            staggerChildren: 0.07,
            delayChildren: 0.5
        },
    }
}


function NavLink(props) {
    return (
        <motion.div
            variants={animation}
            whileHover={{
                scale: 1.05,
            }}
            whileTap={{ scale: 0.95 }}
        >

            <div className="nb-title"><a className="nb-a" href={props.url}><span className="hidden-m"><span className="nb-highlight">dylan@portfolio</span>:</span><span className="nb-highlight">~/home</span>$ cd /{props.title}</a></div>

        </motion.div>
    );
}

export default NavLink;
