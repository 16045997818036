
import './App.css';
import Navbar from './Components/Navigation/Navbar.js';
import Section from './Components/Section/Section';

function App() {
  return (
    <div className="App w-100">
      <header className="">
      </header>
      <Navbar />

      <Section />

    </div>
  );
}

export default App;
