import "./About.css";

function About() {
  return (
    <div className="about-main w-100">
      <div className="about-blurb">
        <span className="caption-small">~/public/about.txt</span>
        <br />
        <br />
        it's nice to meet you. i'm dylan.
        <br />
        <br />
        i love game and web development, but I dabble with AI and embedded
        systems from time to time. i'm focused on building user experiences that
        feel natural, satisfying & leave a lasting impression.
        <br />
        <br />
        games connect us to others and inspire us - they are the fusion of art,
        cutting edge technology, and a whole lot of passion. they are the
        product of millions of lines of code (at least AAA titles), hundreds of
        iterations on different concepts and ideas, and are only possible when
        we come together.
        <br />
        <br />
        this kind of collective achievement is what has drawn me to computer
        science, as the computer itself is a testament to the brilliant things
        we can accomplish when we come together: it's basically a rock that we
        taught to think.
        <br />
        <br />
        sometimes when i unlock my phone or queue up for a game of league, i'll
        pause and feel a little overwhelmed at how people, just like you and i,
        built it. and, while i'm old enough to know it isn't magic, it still{" "}
        <em>feels</em> like magic.
        <br />
        <br />
        my goal is to inspire others in the exact same way: with computer magic.
        <br />
        <br />
      </div>
    </div>
  );
}

export default About;
