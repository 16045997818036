import './Navbar.css'

import { motion } from "framer-motion";
import NavLink from "./NavLink"


const animation = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
            staggerChildren: 0.07,
            delayChildren: 0.2
        },

    },
    exit: {
        opacity: 0,
        transition: {
            staggerChildren: 0.07,
            delayChildren: 0.2
        },
    }
}

const links = [[0, "https://github.com/dylanleclair", "github"], [1, "https://www.linkedin.com/in/dylandevelops/", "linkedin"], [2, "https://codepen.io/dylanleclair", "codepen"]]

function Links() {
    return (

        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={animation}
            className="nb-links">
            {links.map((x) => (
                <NavLink key={x[0]} url={x[1]} title={x[2]} />
            ))}
        </motion.div>
    );
}

export default Links;
