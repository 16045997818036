import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import Modal from "../Modal/Modal";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./Cards.css";

const jobs = [
  {
    company: "Electronic Arts",
    title: "Software Engineer Co-op - Procedural",
    caption: "electronic-arts",
    image:
      "https://officesnapshots.com/wp-content/uploads/2018/04/electronic-arts-offices-montreal-4-700x467.jpg",
    credit: "credit:officesnapshots.com",
    time: "Dec/21 to Apr/21",
    contents: (
      <div>
        <div># team: frostbite engine - procedural tools</div>
        <br />
        <div>montreal,qc (remote from calgary)</div>
        <br />
        <div>## job title: software engineer co-op - procedural</div>
        <br />
        <div>
          at EA, I worked on AssetGraph, a procedural content tool in the
          Frostbite game engine (used to make Battlefield, Mass Effect, FIFA,
          etc.). specifically, I gave technical artists the ability to work with
          subregions of a level, allowing them to iterate on game levels and
          content generation tools faster while better supporting multi-user
          workflows.
        </div>
        <br />
        <div>## key takeaways</div>
        <div>- experience working at a AAA game development company</div>
        <div>- able to contribute to massive, complex codebases</div>
        <div>
          - persistent, built on several moving pieces (reworks of underlying
          systems)
        </div>
        <div>
          - learned how to use C++ and C# together via managed code layer
        </div>

        <br />
        <div>## why i love it</div>
        <br />
        <div>- experience in games industry! (dream come true)</div>
        <div>- incredibly talented team</div>
        <div>- awesome culture 💖 </div>
      </div>
    ),
  },

  {
    company: "BlackBerry Certicom",
    title: "Software Developer Student",
    caption: "certicom",
    image:
      "https://www.totalarea.com/common/images/blackberry-ces1-full.jpg",
    credit: "credit:totalarea.com",
    time: "Dec/21 to Apr/21",
    contents: (
      <div>
        <div># subsidiary: blackberry certicom</div>
        <br />
        <div>mississauga,on (remote from calgary)</div>
        <br />
        <div>## job title: software developer student</div>
        <br />
        <div>
          in this role, i was responsible for developing, testing and helping to
          deploy cybersecurity toolkits in both Java and C/C++.
        </div>
        <br />
        <div>## key takeaways</div>
        <div>- strong attention to detail</div>
        <div>- practiced test driven development</div>
        <div>
          - got to learn about and implement cipher, hash algorithms, etc.
        </div>
        <div>- worked on both development, testing and deployment</div>

        <br />
        <div>## why i love it</div>
        <br />
        <div>- lots of coding</div>
        <div>- new area of expertise</div>
        <div>- awesome team</div>
      </div>
    ),
  },
  {
    company: "BlackBerry QNX",
    title: "Software Developer Student",
    caption: "qnx",
    image:
      "https://geospatialmedia.s3.amazonaws.com/wp-content/uploads/2019/01/black.jpg",
    credit: "credit:geospatialworld",
    time: "Sept/21 to Dec/21",
    contents: (
      <div>
        <div># subsidiary: blackberry qnx</div>
        <br />
        <div>kanata,on (remote from calgary)</div>
        <br />
        <div>## job title: software developer student</div>
        <br />
        <div>
          in this role, i applied and expanded knowledge in operating systems,
          worked directly with customers, and learned all about industry
          software engineering practices.
        </div>
        <br />
        <div>## key takeaways</div>
        <div>- excellent debugging skills</div>
        <div>- worked efficiently across timezones</div>
        <div>- provided direct support to clients</div>
        <div>- deepened understanding of OSs and how they work</div>
        <div>- learned & practiced cybersecurity principles</div>
        <br />
        <div>## why i love it</div>
        <br />
        <div>- tons of learning!!</div>
        <div>- client interaction</div>
        <div>- wicked smart people</div>
      </div>
    ),
  },

  {
    company: (
      <span>
        <span className="hidden-m">University of Calgary</span>
        <span className="disappear-m">University of Calgary</span>
      </span>
    ),
    title: "Teaching Assistant",
    caption: "ucalgary",
    image:
      "https://macleans.ca/_next/image?url=https%3A%2F%2Fcms.macleans.ca%2Fwp-content%2Fuploads%2F2019%2F04%2FUniversity-of-Calgary-Insider-Report-768x512.jpg&w=640&q=75",
    credit: "credit:Ewan Nicholson",
    time: "Sept/20 to Apr/21",
    contents: (
      <div>
        <div># faculty: computer science</div>
        <br />
        <div>calgary, ab</div>
        <br />
        <div>## job title: reader/demonstrator (TA)</div>
        <br />
        <div>
          in fall 2020, i took on this role as a full time student with another
          part time job - this didn't stop me from going above and beyond,
          though! i went on to earn a teaching excellence award, which came with
          a $500 scholarship.
        </div>
        <br />
        <div>## key takeaways</div>
        <div>- diligent, prepared and organized</div>
        <div>- enthusiastic about tech</div>
        <div>- outstanding communication skills</div>
        <div>- very experienced with I2C, GPIO and more (Raspberry Pi)</div>
        <div>- skilled at combinatorial, bitwise logic</div>
        <div>
          - also taught proofs, tree traversals, and other computer science
          fundamentals
        </div>

        <br />
        <div>## why i loved it</div>
        <br />
        <div>- got to help others</div>
        <div>- embedded tech!</div>
        <div>- proofs are fun</div>
      </div>
    ),
  },
];

// const projects = [
//   {
//     company: "League of Legends Analytics",
//     title: (
//       <a class="card-link" href="https://ellaaa.ca">
//         https://ellaaa.ca
//       </a>
//     ),
//     caption: "ellaaa",
//     image:
//       "https://data.ellaaa.ca/static/cdn/img/champion/splash/Shyvana_8.jpg",
//     credit: "credit:riot games",
//     time: "",
//     contents: (
//       <div>
//         <div># a full stack statistics website</div>
//         <br />
//         <div>
//           this project uses the Riot Games API to fetch and cache data so that
//           players can review their performance and gain insights into the game
//         </div>
//         <br />
//         <div>
//           i'm currently in the process of integrating more data science into
//           this to add features like predicting drafts, champion builds, and more
//           detailed game statistics.
//         </div>
//         <br />
//         <div>## technologies</div>
//         <br />
//         <div>- React (frontend)</div>
//         <div>- Django (backend)</div>
//         <div>- MySQL (database)</div>
//         <div>- Linode Cloud (host)</div>
//         <br />
//         <div>## skills used</div>
//         <br />
//         <div>css, html, javascript, python, sql</div>
//       </div>
//     ),
//   },
//   {
//     company: "Space Exploration Via Images",
//     title: (
//       <a class="card-link" href="http://caskaydia.com">
//         http://caskaydia.com
//       </a>
//     ),
//     caption: "nasaapi",
//     image:
//       "https://www.nasa.gov/sites/default/files/styles/full_width_feature/public/thumbnails/image/hubble_ngc2024_flame1_wfc3_ir_display.jpg",
//     credit: "credit:nasa",
//     time: "",
//     contents: (
//       <div>
//         <div># a full stack image centered website</div>
//         <br />
//         <div>
//           powered by the NASA astronomy photo of the day API, this project lets
//           you find similar-coloured images to explore space. it uses cookies to
//           save liked images, with a gallery to view them.
//         </div>
//         <br />
//         <div>fun fact: i developed this in a about a week after work!</div>
//         <br />
//         <div>## technologies</div>
//         <br />
//         <div>- React (frontend)</div>
//         <div>- Django (backend)</div>
//         <div>- MySQL (database)</div>
//         <div>- Linode Cloud (host)</div>
//         <br />
//         <div>## skills used</div>
//         <br />
//         <div>css, html, javascript, python, sql</div>
//       </div>
//     ),
//   },

//   {
//     company: (
//       <span>
//         <span className="hidden-m">Github Projects</span>
//         <span className="disappear-m">U of C</span>
//       </span>
//     ),
//     title: (
//       <a class="card-link" href="https://caskaydia.com">
//         https://github.com/dylanleclair
//       </a>
//     ),
//     caption: "ucalgary",
//     image:
//       "https://raw.githubusercontent.com/dylanleclair/curve-editor/main/images/curve_demo.gif",
//     time: "",
//     contents: (
//       <div>
//         <div># a variety of projects from AI to game and web development</div>
//         <br />
//         <div>
//           view my github portfolio to see what I've been working on most
//           recently!
//         </div>
//       </div>
//     ),
//   },
// ];

const projects = [
  {
    title: "ZZZA",
    link: (
      <a class="project-link" href="https://github.com/dylanleclair/zzza">
        view the source
      </a>
    ),
    caption: "for the commodore VIC-20",
    images: [
      "/images/zzza/zzza1.jfif",
      "/images/zzza/zzza2.jfif",
      "/images/zzza/zzza3.jfif",
      "/images/zzza/zzza4.jfif",
      "/images/zzza/zzza5.jfif",
      "/images/zzza/zzza6.jfif",
      "/images/zzza/zzza7.jfif",
    ],
    credit: "credit:riot games",
    description:
      "Worked on a small team to develop a fast-paced, fun to play auto-scroller in 6502 assembly with an emphasis on satisfying controls and instant user feedback.",
    tech: ["assembly", "make", "dasm"],
  },
  {
    title: "Caskaydia Chess",
    link: (
      <a class="project-link" href="https://github.com/dylanleclair/guacamole">
        view the source
      </a>
    ),
    caption: "a chess live service app",
    images: [
      "/images/caskaydia/image1.png",
      "/images/caskaydia/image2.png",
      "/images/caskaydia/image14.png",
      "/images/caskaydia/image20.png",
      "/images/caskaydia/image3.png",
      "/images/caskaydia/image9.png",
      "/images/caskaydia/image11.png",
      "/images/caskaydia/image12.png",
      "/images/caskaydia/image5.png",
      "/images/caskaydia/image13.png",
    ],
    credit: "credit:riot games",
    description:
      "As our web systems term project, I helped develop a full stack live chess service, where users can play against one another or a computer player in realtime. We also implemented a paid premium tier with Stripe that unlocks chess puzzles and AI assisted analysis.",
    tech: ["TypeScript", "Nextjs", "Socketio", "Stripe", "MongoDB", "Docker"],
  },

  {
    title: "League of Legends Analytics",
    link: (
      <a class="project-link" href="https://ellaaa.ca">
        https://ellaaa.ca
      </a>
    ),
    caption: "a full stack LoL statistics website",
    images: ["/images/ellaaa/landing.JPG", "/images/ellaaa/main.JPG"],
    credit: "credit:riot games",
    description:
      "This project uses the Riot Games API to fetch and cache data so that players can review their match history.",
    tech: ["JavaScript", "Python", "React", "Django", "MySQL", "Linode"],
  },

  {
    title: "Curve Editor",
    link: (
      <a
        class="project-link"
        href="https://github.com/dylanleclair/curve-editor"
      >
        view the source
      </a>
    ),
    caption: "a pottery-like graphics experiment",
    images: [
      "https://raw.githubusercontent.com/dylanleclair/curve-editor/main/images/curve_demo.gif",
    ],
    credit: "credit:riot games",
    description:
      "A computer graphics assignment where you can build an object from a surface of revolution and view randomly generated meshes, with two subdivision algorithms and a dynamic fps-like 3d camera.",
    tech: ["OpenGL", "C++"],
  },

  {
    title: "Dots & Boxes",
    link: (
      <a class="project-link" href="https://dylanleclair.github.io/dotsnboxes/">
        try it out
      </a>
    ),
    caption: "a local version of dots & boxes",
    images: ["/images/demo_dotsnboxes.gif"],
    credit: "credit:riot games",
    description:
      "A simple frontend app where you can play dots and boxes against another person from the same computer! (closed source since it was an assignment)",
    tech: ["JavaScript", "React"],
  },
  {
    title: "Puyo Puyo AI",
    link: (
      <a class="project-link" href="https://github.com/dylanleclair/puyoai">
        view the report / source
      </a>
    ),
    caption: "a simple AI for Puyo Puyo",
    images: ["/images/puyoai.gif"],
    credit: "credit:riot games",
    description:
      "Using neuroevolution to train a neural network, I developed a rather primitive Puyo AI. The fun part of this experiment was trying to make the AI consistently better over time spent training.",
    tech: ["Python"],
  },
  {
    title: "Quidditch Simulation",
    link: (
      <a class="project-link" href="https://github.com/dylanleclair/quids">
        view the source
      </a>
    ),
    caption: "a multi-agent systems experiment",
    images: ["/images/quids.gif"],
    credit: "credit:riot games",
    description:
      "As part of an emergent computing course, we explored how giving agents in a multi-agent system would affect the behaviour of the system as a whole.",
    tech: ["C#", "Unity"],
  },
  {
    title: "More Projects",
    link: (
      <a class="project-link" href="https://github.com/dylanleclair/">
        find them on github
      </a>
    ),
    caption: "the rest of my portfolio",
    image:
      "https://data.ellaaa.ca/static/cdn/img/champion/splash/Shyvana_8.jpg",
    images: ["/images/more1.jfif", "/images/more2.png", "/images/more3.jfif"],
    credit: "credit:riot games",
    description:
      "In addition to these projects, I've got a bunch of samples of my work in computer graphics, game development and more.",
    skills: ["javascript", "python", "css", "html", "sql"],
    tech: [],
  },
];

const clubs = [];

function Job({ job, exit }) {
  return (
    <div className="modal-contents">
      <div>
        <div className="title">
          <span className="hidden-m">
            <span className=" accent">dylan@portfolio</span>:
            <span className="accent"></span>~/home
          </span>
          $ cat{" "}
          <span className="accent">
            ./public/<span className="hidden-xl accent">jobs/</span>
            {job.company}.md
          </span>
        </div>
        {job.contents}
      </div>
      <motion.div
        className="modal-clear"
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.95 }}
        onClick={exit}
      >
        $ <span className="accent">clear</span>
      </motion.div>
    </div>
  );
}

const Card = ({ job }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  return (
    <div className="flex-centered w-100">
      <motion.div
        className="card flex-centered"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.05 }}
        onClick={() => (modalOpen ? close() : open())}
      >
        <div className="card-content-container">
          <div className="fade"></div>
          <img
            className="card-img"
            src={job.image}
            alt=""
          />

          <div className="card-overlay">
            <div>
              <div className="card-over-title">{job.company}</div>
              <div className="card-over-caption">{job.title}</div>
              <div className="card-img-time">{job.time}</div>
            </div>
            <div>
              <div className="card-img-credit">{job.credit}</div>
            </div>
          </div>

          <div className="caption-small indent">
            ~/public/data/{job.caption}.json
          </div>
        </div>
      </motion.div>

      <AnimatePresence
        inital={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {modalOpen && (
          <Modal handleClose={close}>
            <Job exit={close} job={job} />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

/** we definitely need to change the projects section. */

function SimpleCarousel(images) {
  return (
    <Carousel
      infiniteLoop={true}
      interval={1500}
      autoPlay={true}
      showThumbs={false}
      showStatus={false}
      dynamicHeight={true}
    >
      {images.map((x, i) => {
        return (
          <div key={i}>
            <img className="project-image" src={x} alt="" />
          </div>
        );
      })}
    </Carousel>
  );
}

function Projects() {
  return (
    <div className="card-container w-100">
      {projects.map((x, i) => (
        <Project key={i} index={i} project={x} />
      ))}
    </div>
  );
}

function Badge(content) {
  if (content === "C++") {
    return <span className={`badge badge-cpp`}>C++</span>;
  } else if (content === "C#") {
    return <span className={`badge badge-c-sharp`}>C#</span>;
  }

  return (
    <span className={`badge badge-${content.toLowerCase()}`}>{content}</span>
  );
}

function Project(props) {
  console.log(props);
  const reversed = props.index % 2 === 0 ? "" : "project-reversed";
  const project = props.project;
  return (
    <div className={`project-container ${reversed}`}>
      {/* <img
        className="project-image"
        src={props.project.image}
        alt="project preview"
      ></img> */}

      <div className="project-carousel">{SimpleCarousel(project.images)}</div>

      <div className="project-description">
        <div className="project-title">{project.title}</div>
        <div className="">{project.caption}</div>
        <div className="project-short-desc">{project.description}</div>
        <div className="badges">
          {project.tech.map((x, i) => {
            return Badge(x);
          })}
        </div>
        {props.project.link}
      </div>
    </div>
  );
}

function ProjectPanel(projects) {
  let results = [];

  let lol = {
    title: "League of Legends Analytics",
    link: "https://ellaaa.ca",
    description: "ellaaa",
    image:
      "https://data.ellaaa.ca/static/cdn/img/champion/splash/Shyvana_8.jpg",
    tech: ["django", "sql"],
  };

  for (let project of projects) {
    results.push();
  }

  return <div></div>;
}

function Jobs() {
  return (
    <div className="card-container w-100">
      {jobs.map((x, i) => (
        <Card key={i} job={x} />
      ))}
    </div>
  );
}

function Clubs() {
  return (
    <div className="card-container w-100">
      {clubs.map((x, i) => (
        <Card key={i} job={x} />
      ))}
    </div>
  );
}

export { Clubs, Jobs, Projects };
