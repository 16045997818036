import './Navbar.css'

import { motion, AnimatePresence } from "framer-motion";
import { useState } from 'react';
import Links from './Links'
const navbar = {
    open: {
        height: "200px",

    },
    closed: {
        height: "60px",
        transition: { delay: 0.3 }
    }
};


function Navbar() {
    const [isActive, setActive] = useState(false);
    return (

        <nav
            className="flex-centered w-100"
        >
            <div className="nb-container flex-centered">

                <motion.div className="nb-base"
                    initial={false}
                    animate={isActive ? "open" : "closed"}
                    onMouseEnter={() => { setActive(true) }}
                    onMouseLeave={() => { setActive(false) }}
                    variants={navbar}
                >
                    <div className="nb-title">
                        <span className="hidden-m"><span className="nb-highlight">dylan@portfolio</span>:</span><span className="nb-highlight">~/home</span>$
                    </div>

                    <AnimatePresence
                        inital={false}
                        exitBeforeEnter={true}
                        onExitComplete={() => null}
                    >
                        {isActive && <Links active={isActive} />}

                    </AnimatePresence>



                </motion.div>
            </div >
        </nav >

    );
}

export default Navbar;
