import { motion } from "framer-motion"


import './Modal.css'

const animation = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500
        }
    },
    exit: {
        y: "100vh",
        opacity: 0
    }
}

const Backdrop = ({ children, onClick }) => {
    return (
        <motion.div
            className="backdrop"
            onClick={onClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {children}
        </motion.div>
    )
}



const Modal = ({ children, handleClose }) => {

    return (
        <Backdrop onClick={handleClose}>

            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal"
                variants={animation}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                {children}

            </motion.div>
        </Backdrop>
    )

};

export default Modal;