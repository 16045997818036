import "./Introduction.css";

function Introduction() {
  return (
    <div className="intro-main w-100">
      <div className="intro-title title">
        <div className="xs">~/public/images/profile.jpg</div>
        <div className="intro-title">Dylan Leclair</div>
        <div className="small">university of calgary</div>
        <div className="small">computer science</div>
        <div className="small intro-bar w-40">
          <hr className="bar-inner"></hr>
        </div>
        <div className="small">software engineer</div>
        <div className="small">prev: electronic arts, blackberry</div>
        <div className="small">calgary,ab</div>
      </div>

      <figure className="intro-figure">
        <img className="profile" src={"/images/headshot.jpg"} alt="Me." />
      </figure>
    </div>
  );
}

export default Introduction;
